import React, { useState, useEffect, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSetState } from "react-hanger";
import moment from "moment";

import { fetchAdminCompanies } from "modules/companies";
import { flashSuccess, flashError } from "lib/flash";

import { setURLParams, getUrlParams } from "lib/utils";
import {
  withUIPaginate,
  UIPaginationTotalSelector,
  UITableSortable,
  UIPagination,
} from "components/ui";
import AdminPanelHeader from "../../common/admin-panel-header";
import AdminPanelIndexControls from "../../common/admin-panel-index-controls";

const Companies = (props) => {
  console.log("Companies", props);
  const [all_company_categories, setAllCompanyCategories] = useState([]);
  const [all_referral_sources, setAllReferralSources] = useState([]);
  const [all_sales_directors, setAllSalesDirectors] = useState([]);
  const [all_location_states, setAllLocationStates] = useState([]);
  const [all_naics_sectors, setAllNaicsSectors] = useState([]);
  const [companies, setCompanies] = useState(props.companies || []);
  const [isActiveFilterActive, setIsActiveFilterActive] = useState(true);
  const [isInactiveFilterActive, setIsInactiveFilterActive] = useState(false);
  const [isIntegratedFilterActive, setIsIntegratedFilterActive] =
    useState(false);
  const [selectedCompanyCategories, setSelectedCompanyCategories] = useState(
    []
  );
  const [selectedLastTouched, setSelectedLastTouched] = useState([]);
  const [selectedReferralSources, setSelectedReferralSources] = useState([]);
  const [selectedSalesReps, setSelectedSalesReps] = useState([]);
  const [selectedLocationStates, setSelectedLocationStates] = useState([]);
  const [selectedNaicsSectors, setSelectedNaicsSectors] = useState([]);
  const loggedOutDashboardURL = useRef(null);
  const url = new URL(window.location);

  const { state, setState } = useSetState({
    query: getUrlParams(url, "query") || "",
    sortColumn: getUrlParams(url, "sortColumn") || "name",
    sortDirection: getUrlParams(url, "sortDirection") || "ASC",
  });

  console.log("Companies", props.companies);

  const lastTouchedOptions = [
    "Company created",
    "Discovery",
    "Platform invite",
    "Integration",
    "User log-in",
    "Note",
    "Company Outreach",
    "Incentive Review",
    "Other Meeting",
    "Application started",
  ];

  const { pagination = { page: 1, per_page: 50, total: 0, first_load: true } } =
    props;

  useEffect(() => {
    fetchCompanies();
  }, [
    state,
    selectedCompanyCategories,
    selectedReferralSources,
    selectedSalesReps,
    selectedLocationStates,
    selectedNaicsSectors,
    isIntegratedFilterActive,
    isActiveFilterActive,
    isInactiveFilterActive,
    selectedLastTouched,
    pagination.page,
    pagination.per_page,
  ]);

  useEffect(() => {
    if (pagination.first_load) return;
    fetchCompanies();
  }, [pagination]);

  const fetchCompanies = () => {
    const params = {
      page: pagination.page,
      per_page: pagination.per_page,
      ...pagination,
      ...state,
      company_categories: selectedCompanyCategories.map(
        (option) => option.value
      ),
      referral_sources: selectedReferralSources.map((option) => option.value),
      company_category: selectedCompanyCategories.map((option) => option.value),
      referral_source: selectedReferralSources.map((option) => option.value),
      sales_rep_id: selectedSalesReps.map((option) => option.value),
      location_state: selectedLocationStates.map((option) => option.value),
      naics_sector: selectedNaicsSectors.map((option) => option.value),
      sort_column: state.sortColumn,
      sort_direction: state.sortDirection,
      is_active:
        isActiveFilterActive && !isInactiveFilterActive
          ? true
          : !isActiveFilterActive && isInactiveFilterActive
          ? false
          : undefined,
      integrated_accounting_software: isIntegratedFilterActive
        ? true
        : undefined,
      last_touched: selectedLastTouched.map((option) => option.value),
    };

    fetchAdminCompanies(params).then((data) => {
      if (!data.success) {
        return flashError(data.message);
      }

      const companiesWithAdditionalData = data.companies.map((company) => {
        return {
          ...company,
          admin_company_join_data: company.admin_company_join_data,
        };
      });
      setCompanies(data.companies);
      setAllCompanyCategories(data.all_company_categories);
      setAllReferralSources(data.all_referral_sources);
      setAllSalesDirectors(data.all_sales_directors);
      setAllLocationStates(data.all_location_states);
      setAllNaicsSectors(data.all_naics_sectors);

      props.updatePagination({
        total: data.total,
        first_load: true,
        total_pages: data.total_pages,
        page: pagination.page,
        per_page: pagination.per_page,
      });
    });
  };

  const keywordSearchConfig = {
    state: state,
    setState: setState,
    updatePagination: props.updatePagination,
  };

  const multiSelectConfigs = [
    {
      onChange: (selectedCategories) =>
        setSelectedCompanyCategories(selectedCategories),
      options: all_company_categories.map((category) => ({
        value: category,
        label: category,
      })),
      value: selectedCompanyCategories,
      className: "ui-multi-filter",
      placeholder: "Category",
    },
    {
      onChange: (selectedSources) =>
        setSelectedReferralSources(selectedSources),
      options: all_referral_sources.map((source) => ({
        value: source.id,
        label: source.name,
      })),
      value: selectedReferralSources,
      className: "ui-multi-filter",
      placeholder: "Referral Source",
    },
    {
      onChange: (selectedReps) => setSelectedSalesReps(selectedReps),
      options: all_sales_directors.map((rep) => ({
        value: rep.id,
        label: rep.name,
      })),
      value: selectedSalesReps,
      className: "ui-multi-filter",
      placeholder: "Sales Contact",
    },
    {
      onChange: (selectedStates) => setSelectedLocationStates(selectedStates),
      options: all_location_states.map((state) => ({
        value: state,
        label: state,
      })),
      value: selectedLocationStates,
      className: "ui-multi-filter",
      placeholder: "Location State",
    },
    {
      onChange: (selectedSectors) => setSelectedNaicsSectors(selectedSectors),
      options: all_naics_sectors.map((sector) => ({
        value: sector,
        label: sector,
      })),
      value: selectedNaicsSectors,
      className: "ui-multi-filter",
      placeholder: "NAICS Sector",
    },
  ];

  const buttonGroupConfigs = [
    {
      href: "#",
      className: isActiveFilterActive ? "btn-subdued-active" : "btn-subdued",
      onClick: (e) => handleActiveFilter(e),
      label: "Active",
    },
    {
      href: "#",
      className: isInactiveFilterActive ? "btn-subdued-active" : "btn-subdued",
      onClick: (e) => handleInactiveFilter(e),
      label: "Inactive",
    },
    {
      href: "#",
      className: isIntegratedFilterActive
        ? "btn-subdued-active"
        : "btn-subdued",
      onClick: (e) => handleIntegratedFilter(e),
      label: "Integrated",
    },
  ];

  const renderDateAndActions = (company) => {
    const { is_active, last_touched } = company;

    return (
      <div className="date-action-container">
        <div className="date-wrapper">
          {last_touched
            ? moment(last_touched.date_of_action).format("MM/DD/YYYY")
            : ""}
        </div>
        <div className="action-wrapper">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip className="cashflow-tooltip">Copy report URL</Tooltip>
            }
          >
            <button
              className="btn btn-sm btn-outline-secondary action-buttons"
              onClick={() => handleCopyUrl(company.uuid)}
            >
              <i className="bi bi-clipboard"></i>
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip className="cashflow-tooltip">Details</Tooltip>}
          >
            <a
              href={`/admin/companies/${company.id}/edit`}
              className="btn btn-sm btn-outline-secondary action-buttons"
            >
              <i class="bi bi-chevron-right"></i>
            </a>
          </OverlayTrigger>
        </div>
      </div>
    );
  };

  const handleIntegratedFilter = (e) => {
    e.preventDefault();
    setIsIntegratedFilterActive(!isIntegratedFilterActive);
  };

  const handleActiveFilter = (e) => {
    e.preventDefault();
    setIsActiveFilterActive(!isActiveFilterActive);
    setIsInactiveFilterActive(false);
  };

  const handleInactiveFilter = (e) => {
    e.preventDefault();
    setIsActiveFilterActive(false);
    setIsInactiveFilterActive(!isInactiveFilterActive);
  };

  const onSortChange = (direction, column) => {
    setState({
      sortDirection: direction,
      sortColumn: column,
    });
  };

  const handleCopyUrl = (uuid) => {
    const urlToCopy = `subcity.com/incentives/${uuid}/dashboard`;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        flashSuccess("Incentive report URL copied successfully!");
        console.log("URL copied successfully!");
      })
      .catch((err) => {
        console.error("Could not copy URL", err);
        flashError(`Error copying URL: ${err}`);
      });
  };

  return (
    <>
      <AdminPanelHeader
        title="Companies"
        singularTitle="Company"
        addNewUrl="/admin/companies/new"
        exportCsvUrl="/download/companies.csv"
        importCsvUrl="/admin/companies/import"
      />
      <AdminPanelIndexControls
        keywordSearchConfig={keywordSearchConfig}
        multiSelectConfigs={multiSelectConfigs}
        buttonGroupConfigs={buttonGroupConfigs}
      />
      <div className="px-3">
        <table className="table admin-table">
          <thead>
            <tr>
              <th className="desktop-only">
                <UITableSortable
                  title="Id"
                  sortColumn="id"
                  sortDirection={state.sortDirection}
                  currentSortColumn={state.sortColumn}
                  onSortChange={onSortChange}
                />
              </th>
              <th>
                <UITableSortable
                  title="Name"
                  sortColumn="name"
                  sortDirection={state.sortDirection}
                  currentSortColumn={state.sortColumn}
                  onSortChange={onSortChange}
                />
              </th>
              <th>Sector</th>
              <th>Location</th>
              <th>Owner</th>
              <th>Matches</th>
              <th>
                <UITableSortable
                  title="Users"
                  sortColumn="user_companies_count"
                  sortDirection={state.sortDirection}
                  currentSortColumn={state.sortColumn}
                  onSortChange={onSortChange}
                />
              </th>
              <th className="desktop-only">Status</th>
              <th>Last Update</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => {
              const city = company.address.match(/, (.+), /);
              return (
                <tr className="row-with-shadow" key={`user-row-${index}`}>
                  <td className="px-3 admin-metadata desktop-only">
                    {company.id}
                  </td>
                  <td>
                    <div className="user-info">
                      <p className="mb-0 d-inline">{company.name}</p>
                      {company.company_category && (
                        <span className="badge incentive-type-badge ms-2">
                          {company.company_category}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    {company.naics_sector_title && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip className="cashflow-tooltip">
                            {company.naics_title}
                          </Tooltip>
                        }
                      >
                        <span>{company.naics_sector_title}</span>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>{company.primary_address_state}</td>
                  <td>{company.sales_director_name}</td>
                  <td>{company.qualified_incentives_count}</td>
                  <td>{company.users_count}</td>
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip className="cashflow-tooltip">
                          {company.is_active ? "Active" : "Inactive"}
                        </Tooltip>
                      }
                    >
                      <i
                        className={`bi ${
                          company.is_active ? "bi-check-circle" : "bi-x-circle"
                        }`}
                        style={{
                          color: company.is_active ? "#00A2E9" : "#E22947",
                        }}
                      ></i>
                    </OverlayTrigger>
                  </td>
                  <td>{renderDateAndActions(company)}</td>
                </tr>
              );
            })}
            <tr className="pagination-row">
              <td colSpan={2}>
                <div className="mt-2 pagination-selector">
                  <UIPaginationTotalSelector
                    perPage={pagination.per_page}
                    changePerPage={(value) => {
                      props.updatePagination({
                        per_page: value,
                        first_load: false,
                      });
                    }}
                  />
                </div>
              </td>

              <td colSpan={6}>
                <span className="float-end mt-2 pagination-controls">
                  {pagination.total > 0 ? (
                    <UIPagination
                      page={pagination.page}
                      perPage={pagination.per_page}
                      changePage={(page) => {
                        props.updatePagination({
                          page: page.selected + 1,
                          per_page: pagination.per_page,
                        });
                      }}
                      total={pagination.total}
                    />
                  ) : null}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default withUIPaginate(Companies);
