import React, { useEffect, useState, useCallback } from "react";

// Third-party library imports
import { Button, Spinner } from "react-bootstrap";

// Custom module imports
import { fetchAdminQualifications } from "modules/qualifications";
import { createCompanyAnswer, destroyCompanyAnswer } from "modules/companies";
import { flashSuccess, flashError } from "lib/flash";

// UI component imports
import { UIMultiSelect } from "../../../ui";
import QuestionCard from "../common/question-card";
import RunQualifyJobButton from "../common/run-qualify-job-button";

const QualifyingQuestions = ({ company }) => {
  console.log("q questions - company", company);
  // Qualification state
  const [qualifications, setQualifications] = useState([]);
  const [categories, setCategories] = useState([]);

  // Answer state
  const [companyAnswers, setCompanyAnswers] = useState(
    company.company_answers || []
  );
  const [tempAnswerText, setTempAnswerText] = useState({});
  const [activeInput, setActiveInput] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // Filter and sorting state
  const [filterCondition, setFilterCondition] = useState("all");
  const [state, setState] = useState({
    category: [],
  });
  const [smartSort, setSmartSort] = useState(true);

  // Pagination state
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    perPage: 15,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Derived state
  const categoryOptions = categories.map((category) => ({
    value: category,
    label: category,
  }));

  // Main data fetching function
  const fetchQualifications = useCallback(
    async (page = 1, append = false) => {
      setIsLoading(true);
      try {
        const params = {
          page,
          per_page: pagination.perPage,
          category: state.category.join(","),
          company_id: company.id,
          smart_sort: smartSort ? "true" : "false",
          filter_condition: filterCondition,
        };
        const data = await fetchAdminQualifications(params);

        if (data) {
          setPagination((prev) => ({
            ...prev,
            currentPage: data.pagination.current_page,
            totalPages: Math.ceil(
              data.pagination.total / data.pagination.per_page
            ),
            totalCount: data.pagination.total,
          }));

          setQualifications((prev) =>
            append ? [...prev, ...data.qualifications] : data.qualifications
          );

          setCategories(data.categories);
        }
      } catch (error) {
        flashError("Failed to fetch qualifications. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [company.id, filterCondition, smartSort, pagination.perPage]
  );

  // Effect to fetch qualifications on mount and when dependencies change
  useEffect(() => {
    fetchQualifications(1, false);
  }, [fetchQualifications]);

  // Helper functions for answer management
  const getCompanyAnswerText = (qualificationId) => {
    const answer = companyAnswers.find(
      (a) => a.qualification_id === qualificationId
    );
    return answer ? answer.answer_text : null;
  };

  const isAnswerSelected = (answerId) => {
    return companyAnswers.some((answer) => answer.answer_id === answerId);
  };

  const isQuestionAnswered = (qualificationId) => {
    return companyAnswers.some(
      (answer) => answer.qualification_id === qualificationId
    );
  };

  const getCompanyAnswerUserDetails = (qualificationId) => {
    const answer = companyAnswers.find(
      (ans) => ans.qualification_id === qualificationId
    );
    if (answer && (answer.user_name || answer.user_email)) {
      return answer;
    }
    return null;
  };

  // Filter and pagination handlers
  const handleFilterChange = (newFilterCondition) => {
    setFilterCondition(newFilterCondition);
    fetchQualifications(1, false);
  };

  const handleLoadMore = () => {
    if (pagination.currentPage < pagination.totalPages) {
      fetchQualifications(pagination.currentPage + 1, true);
    }
  };

  const handleSelect = (selectedOptions) => {
    setState((prevState) => ({
      ...prevState,
      category: selectedOptions.map((option) => option.value),
    }));
    fetchQualifications(1, false);
  };

  // Answer interaction handlers
  const handleAnswerClick = async (qualificationId, answerId) => {
    const existingAnswer = companyAnswers.find(
      (answer) =>
        answer.qualification_id === qualificationId &&
        answer.answer_id === answerId
    );

    if (existingAnswer) {
      if (window.confirm("This will remove the existing answer. Continue?")) {
        handleAnswerRemove(company.id, existingAnswer.id);
      }
      return;
    }

    if (isQuestionAnswered(qualificationId)) {
      if (
        !window.confirm("This will overwrite the existing answer. Continue?")
      ) {
        return;
      }
    }

    // Optimistic update
    const newAnswer = {
      company_id: company.id,
      qualification_id: qualificationId,
      answer_id: answerId,
      answer_text: null,
    };

    setCompanyAnswers((prevAnswers) => {
      const filteredAnswers = prevAnswers.filter(
        (answer) => answer.qualification_id !== qualificationId
      );
      return [...filteredAnswers, newAnswer];
    });

    setIsUpdating(true);
    try {
      const response = await createCompanyAnswer(company.id, newAnswer);
      if (response.status !== "success") {
        throw new Error("Failed to submit answer");
      }
      flashSuccess("Answer submitted successfully");

      // Refetch qualifications to update sorting
      await fetchQualifications(1, false);
    } catch (error) {
      console.error("An error occurred while submitting the answer:", error);
      flashError("Failed to submit answer. Please try again.");
      // Revert optimistic update
      setCompanyAnswers((prevAnswers) =>
        prevAnswers.filter(
          (answer) => answer.qualification_id !== qualificationId
        )
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAnswerRemove = async (companyId, answerId) => {
    if (!companyId || !answerId) {
      console.error(
        "Company ID or Answer ID is missing. This usually happens when answers are updated too frequently."
      );
      flashError(
        "Answers have been updated too frequently and are now out of sync. Please refresh the page and start over."
      );
      return;
    }

    if (!window.confirm("Are you sure you want to remove this answer?")) {
      return;
    }

    try {
      console.log(
        "Removing answer with company.id:",
        company.id,
        "and answerId:",
        answerId
      );
      const response = await destroyCompanyAnswer(company.id, answerId);

      if (response.status === "success") {
        const updatedAnswers = companyAnswers.filter(
          (answer) => answer.id !== answerId
        );
        setCompanyAnswers(updatedAnswers);
      }
    } catch (error) {
      console.error("An error occurred while removing the answer:", error);
    }
  };

  const handleTextChange = (qualificationId, event) => {
    const answerText = event.target.value;
    setActiveInput(qualificationId);
    setTempAnswerText({
      ...tempAnswerText,
      [qualificationId]: answerText,
    });
  };

  const handleTextSave = async (qualificationId) => {
    try {
      const text = tempAnswerText[qualificationId];
      const params = {
        company_id: company.id,
        qualification_id: qualificationId,
        answer_text: text,
      };

      const response = await createCompanyAnswer(company.id, params);

      if (response.status === "success") {
        flashSuccess("Text answer submitted successfully");

        const existingAnswerIndex = companyAnswers.findIndex(
          (answer) => answer.qualification_id === qualificationId
        );

        if (existingAnswerIndex !== -1) {
          const updatedCompanyAnswers = [...companyAnswers];
          updatedCompanyAnswers[existingAnswerIndex].answer_text = text;
          setCompanyAnswers(updatedCompanyAnswers);
        } else {
          setCompanyAnswers([
            ...companyAnswers,
            {
              company_id: company.id,
              qualification_id: qualificationId,
              answer_id: null,
              answer_text: text,
            },
          ]);
        }

        setActiveInput(null);
        setTempAnswerText({
          ...tempAnswerText,
          [qualificationId]: "",
        });
      } else {
        console.error("Failed to submit text answer", response);
      }
    } catch (error) {
      console.error(
        "An error occurred while submitting the text answer:",
        error
      );
    }
  };

  return (
    <div>
      <h4 className="mb-2">
        {isUpdating ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="mr-2"
            />
            Updating incentive eligibility...
          </>
        ) : (
          <>
            Total Answered: {companyAnswers.length} / {pagination.totalCount}
          </>
        )}
      </h4>

      <div
        className="btn-group"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <button
            className={
              filterCondition === "all" ? "btn-subdued-active" : "btn-subdued"
            }
            onClick={() => handleFilterChange("all")}
          >
            All
          </button>
          <button
            className={
              filterCondition === "level1"
                ? "btn-subdued-active"
                : "btn-subdued"
            }
            onClick={() => handleFilterChange("level1")}
          >
            Intake
          </button>
          <button
            className={
              filterCondition === "answered"
                ? "btn-subdued-active"
                : "btn-subdued"
            }
            onClick={() => handleFilterChange("answered")}
          >
            Answered
          </button>
          <button
            className={
              filterCondition === "unanswered"
                ? "btn-subdued-active"
                : "btn-subdued"
            }
            onClick={() => handleFilterChange("unanswered")}
          >
            Unanswered
          </button>
          <RunQualifyJobButton />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <label
              className="switch"
              style={{
                marginRight: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                id="smartSort"
                checked={smartSort}
                onChange={() => setSmartSort(!smartSort)}
              />
              <span className="slider round"></span>
            </label>
            <span style={{ marginLeft: "4px" }}>Smart Sort</span>{" "}
          </div>
          <div style={{ flexGrow: 1 }}>
            {" "}
            <UIMultiSelect
              onChange={handleSelect}
              options={categoryOptions}
              value={state.category.map((cat) => ({ value: cat, label: cat }))}
              placeholder="Select categories"
              className="ui-multi-filter"
            />
          </div>
        </div>
      </div>

      {qualifications.length > 0 ? (
        <>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {qualifications.map((q, index) => (
              <QuestionCard
                key={q.id}
                question={q}
                handleAnswerClick={handleAnswerClick}
                handleTextChange={handleTextChange}
                handleTextSave={handleTextSave}
                getCompanyAnswerText={getCompanyAnswerText}
                isAnswerSelected={isAnswerSelected}
                userDetails={getCompanyAnswerUserDetails(q.id)}
              />
            ))}
          </ul>

          {pagination.currentPage < pagination.totalPages && (
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <Button onClick={handleLoadMore} disabled={isLoading}>
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Load More"
                )}
              </Button>
            </div>
          )}
        </>
      ) : (
        <p>No qualifications found.</p>
      )}
    </div>
  );
};

export default QualifyingQuestions;
