import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AriseHeader = ({ organizations }) => {
  const [visibleOrgs, setVisibleOrgs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // Load organizations in batches
    const batchSize = 10;
    let currentIndex = 0;

    const loadNextBatch = () => {
      const nextBatch = organizations.slice(
        currentIndex,
        currentIndex + batchSize
      );
      setVisibleOrgs((prev) => [...prev, ...nextBatch]);
      currentIndex += batchSize;

      if (currentIndex < organizations.length) {
        setTimeout(loadNextBatch, 500);
      } else {
        setLoading(false);
      }
    };

    loadNextBatch();
  }, [organizations]);

  const ImageWithFallback = ({ src, alt, fallbackSrc }) => {
    const [imgSrc, setImgSrc] = useState(src);

    return (
      <img
        src={imgSrc}
        alt={alt}
        loading="lazy"
        onError={() => setImgSrc(fallbackSrc)}
      />
    );
  };

  //TODO: Remove arc arise direct link as fallback URL

  return (
    <div className="arise-header">
      <div className="d-flex align-items-center">
        <div className="col-6 arise-hero-copy">
          <h2>Clean Energy Incentives for Appalachian Manufacturers</h2>
          <p>
            Subcity has digitized thousands of local, state, and federal
            economic incentives and tax credits and partnered with Arc Arise to
            match small and medium-sized manufacturers with the best programs
            for them to consider.
          </p>
          <a href="/register">
            Answer a few questions to personalize the list for your company{" "}
            <i className="bi bi-chevron-right icon-next-chevron"></i>
          </a>
        </div>
        <div className="col-6">
          <div className="grid-container ms-4">
            {visibleOrgs.map((org, index) => (
              <OverlayTrigger
                key={org.id}
                placement="bottom"
                overlay={
                  <Tooltip className="cashflow-tooltip">{org.name}</Tooltip>
                }
              >
                <div key={index} className="grid-item">
                  {org.website ? (
                    <a
                      href={org.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ImageWithFallback
                        src={org.logo_url}
                        alt={org.name}
                        fallbackSrc="https://www.arc.gov/wp-content/uploads/2022/08/ARISE-50-50-Graphic.png"
                      />
                    </a>
                  ) : (
                    <ImageWithFallback
                      src={org.logo_url}
                      alt={org.name}
                      fallbackSrc="https://www.arc.gov/wp-content/uploads/2022/08/ARISE-50-50-Graphic.png"
                    />
                  )}
                </div>
              </OverlayTrigger>
            ))}
          </div>
          {loading && <div>Loading more organizations...</div>}
        </div>
      </div>
    </div>
  );
};

export default AriseHeader;
