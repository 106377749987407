import React from "react";

const UIRadioCard = (props) => {
  function handleClick(e) {
    e.preventDefault();
    props.handleClick();
  }

  return (
    <label onClick={handleClick} className="ui-radio-card">
      <input
        type="radio"
        name={props.name}
        id={props.id}
        className="card-input-element"
        checked={props.checked}
        onChange={handleClick}
      />
      <div className="card card-input">
        <div className="card-body py-2 px-4">{props.label}</div>
      </div>
    </label>
  );
};

export default UIRadioCard;
