import React, { useContext, useState } from "react";
import { flashSuccess, flashError } from "lib/flash";

import { inviteUser } from "modules/users";

import { EditCompanyContext } from "components/common/companies/contexts/edit-company-context";
import RoleSelector from "../../common/role-selector";

const Users = ({ roles }) => {
  const {
    users,
    onAddUser,
    updateUserCompanyRole,
    onRemoveUser,
    toggleUserAdmin,
    basicInfo,
  } = useContext(EditCompanyContext);

  const [displayListView, setDisplayListView] = useState(true);
  const [displayInviteView, setDisplayInviteView] = useState(false);

  const showListView = () => {
    setDisplayListView(true);
    setDisplayInviteView(false);
  };

  const showInviteView = () => {
    setDisplayListView(false);
    setDisplayInviteView(true);
  };

  if (displayListView) {
    return (
      <ListView
        roles={roles}
        showInviteView={showInviteView}
        users={users}
        onAddUser={onAddUser}
        updateUserCompanyRole={updateUserCompanyRole}
        onRemoveUser={onRemoveUser}
        toggleUserAdmin={toggleUserAdmin}
        basicInfo={basicInfo}
      />
    );
  }

  if (displayInviteView) {
    return <InviteView showListView={showListView} users={users} />;
  }
};

const InviteView = ({ showListView, users }) => {
  const [inviteForm, setInviteForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const onFieldChange = (type, value) => {
    setInviteForm({
      ...inviteForm,
      [type]: value,
    });
  };

  const onInviteUser = () => {
    return inviteUser({ user: inviteForm })
      .then((res) => {
        flashSuccess(res.message);
        users.push(res.resource);
        showListView();
        return Promise.resolve();
      })
      .catch((res) => {
        res.errors.map((error) => {
          flashError(error);
        });
        return Promise.resolve();
      });
  };

  return (
    <div className="panel">
      <div className="panel-header">
        <h1>Invite New User</h1>
      </div>
      <div className="panel-body">
        <div className="row">
          <div className="col-lg-12">
            <div className="field mb-3">
              <strong>
                <label htmlFor="user_First Name">First name</label>
              </strong>
              <br />
              <input
                className="form-control"
                type="text"
                value={inviteForm.first_name}
                onChange={(e) => {
                  onFieldChange("first_name", e.target.value);
                }}
              />
            </div>
            <div className="field mb-3">
              <strong>
                <label htmlFor="user_Last Name">Last name</label>
              </strong>
              <br />
              <input
                className="form-control"
                type="text"
                value={inviteForm.last_name}
                onChange={(e) => {
                  onFieldChange("last_name", e.target.value);
                }}
              />
            </div>
            <div className="field mb-3">
              <strong>
                <label htmlFor="user_Email">Email</label>
              </strong>
              <br />
              <input
                className="form-control"
                type="text"
                value={inviteForm.email}
                onChange={(e) => {
                  onFieldChange("email", e.target.value);
                }}
              />
            </div>
            <div className="my-2 btn-group float-end">
              <a className="btn btn-light" onClick={showListView}>
                Cancel
              </a>
              <button
                className="btn btn-dark btn-full-width"
                data-disable-with="Inviting"
                onClick={onInviteUser}
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListView = ({
  roles,
  showInviteView,
  users,
  onAddUser,
  updateUserCompanyRole,
  onRemoveUser,
  toggleUserAdmin,
  basicInfo,
}) => {
  return (
    <div className="row mt-3">
      <div>
        {users.value.map((user) => {
          return (
            <div key={user.user_id} className="card p-3 mt-1 mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-danger remove-user-btn btn-sm"
                    onClick={() => onRemoveUser(user)}
                  >
                    <i className="px-1 bi bi-trash"></i>
                  </button>
                  <div>{`${user.full_name} - ${user.email}`}</div>
                </div>
                <div>
                  <RoleSelector
                    update={(role) => {
                      updateUserCompanyRole(user, role);
                    }}
                    selected={{
                      id: user.role_id,
                      name: user.role,
                    }}
                    roles={roles}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Users;
